import { Frame } from "@shopify/polaris";
import ProtectedRoutes from "./ProtectedRoutes";
import { useCallback, useState } from "react";
import SidebarNavigation from "./SidebarNavigation";
import TopBar from "./TopBar";

const LOGO = {
  width: 86,
  topBarSource:
    "https://cdn.shopify.com/s/files/1/2376/3301/files/Shopify_Secondary_Inverted.png",
  contextualSaveBarSource:
    "https://cdn.shopify.com/s/files/1/2376/3301/files/Shopify_Secondary_Inverted.png",
  accessibilityLabel: "Shopify",
};

function ProtectedShell() {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  return (
    <Frame
      logo={LOGO}
      topBar={<TopBar onNavigationToggle={toggleMobileNavigationActive} />}
      navigation={<SidebarNavigation />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
    >
      <ProtectedRoutes />
    </Frame>
  );
}

export default ProtectedShell;
