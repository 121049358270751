import { Frame, Loading } from "@shopify/polaris";
import Login from "../Pages/Login/Login";
import { useAuth } from "../providers/AuthProvider";
import ProtectedShell from "./ProtectedShell";

function Shell() {
  const { user } = useAuth();

  if (user.isLoading) {
    return (
      <Frame>
        <Loading />
      </Frame>
    );
  }

  if (user.isLoggedIn) return <ProtectedShell />;

  return <Login />;
}

export default Shell;
