import { FormLayout, TextField } from "@shopify/polaris";

export type BankAccountDetailsUS = {
  ownerName: string;
  routingNumber: string;
  accountNumber: string;
};

type Props = {
  details: BankAccountDetailsUS;
  onChange: (value: BankAccountDetailsUS) => void;
};

function BankAccountFormUS({ details, onChange }: Props) {
  return (
    <FormLayout>
      <FormLayout.Group>
        <TextField
          label="Account owner name"
          autoComplete="name"
          value={details.ownerName}
          onChange={(value) => onChange({ ...details, ownerName: value })}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField
          label="Routing number"
          autoComplete="bank-routing-number"
          value={details.routingNumber}
          onChange={(value) => onChange({ ...details, routingNumber: value })}
        />
        <TextField
          label="Account number"
          autoComplete="bank-account-number"
          value={details.accountNumber}
          onChange={(value) => onChange({ ...details, accountNumber: value })}
        />
      </FormLayout.Group>
    </FormLayout>
  );
}

export default BankAccountFormUS;
