import "@shopify/polaris/build/esm/styles.css";
import {
  LegacyCard,
  DataTable,
  Page,
  Spinner,
  InlineStack,
  EmptyState,
} from "@shopify/polaris";
import { useEffect, useRef, useState } from "react";
import { fetchOrders } from "./api";

function Orders() {
  const isInitializedRef = useRef(false);
  const [orders, setOrders] = useState<{
    rows: any[][];
    totals: string[];
    count: number;
    hasExactCount: boolean;
    isLoading: boolean;
  }>({
    rows: [],
    totals: [],
    count: 0,
    hasExactCount: true,
    isLoading: true,
  });

  useEffect(() => {
    if (isInitializedRef.current) return;

    isInitializedRef.current = true;

    fetchOrders()
      .then((data) => {
        const rows = data.orders.map(
          ({
            id,
            customerName,
            customerLocation,
            quantity,
            status,
            updatedAt,
            payment,
          }) => [
            id,
            customerName,
            customerLocation,
            quantity,
            status,
            new Intl.DateTimeFormat("en-US", {
              dateStyle: "medium",
              timeStyle: "short",
            }).format(new Date(updatedAt)),
            payment,
          ]
        );
        setOrders({
          rows,
          totals: data.totals,
          count: data.count,
          hasExactCount: data.hasExactCount,
          isLoading: false,
        });
      })
      .catch(() => {
        setOrders({
          rows: [],
          totals: [],
          count: 0,
          hasExactCount: true,
          isLoading: false,
        });
      });
  }, []);

  const dataTableMarkup = (
    <LegacyCard>
      <DataTable
        showTotalsInFooter
        columnContentTypes={[
          "text",
          "text",
          "text",
          "numeric",
          "numeric",
          "text",
          "text",
          "numeric",
        ]}
        headings={[
          "Order",
          "Customer",
          "Location",
          "Net quantity",
          "Status",
          "Last Update",
          "Payment",
        ]}
        rows={orders.rows}
        totals={["", "", "", "", "", "", orders.totals.join(" ➕ ")]}
        footerContent={`Showing ${orders.rows.length} of ${
          orders.hasExactCount ? "" : "at least "
        }${orders.count} orders`}
        totalsName={{
          singular: "Total sales",
          plural: "Total sales",
        }}
      />
    </LegacyCard>
  );

  return (
    <Page title="Orders">
      {orders.isLoading ? (
        <InlineStack align="center">
          <Spinner />
        </InlineStack>
      ) : orders.rows.length ? (
        dataTableMarkup
      ) : (
        <LegacyCard sectioned>
          <EmptyState
            heading="You haven't made any sales yet!"
            image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
          >
            <p>Once you start selling, your orders will appear here.</p>
          </EmptyState>
        </LegacyCard>
      )}
    </Page>
  );
}

export default Orders;
