import { TopBar } from "@shopify/polaris";
import { useAuth, useLoggedInUser } from "../providers/AuthProvider";
import { useMemo, useReducer } from "react";

function UserMenu() {
  const [isOpen, toggle] = useReducer((state) => !state, false);
  const user = useLoggedInUser();
  const { onLogout } = useAuth();

  const initials = useMemo(
    () =>
      user.name
        .split(" ")
        .slice(0, 2)
        .map((str) => str[0])
        .join(""),
    [user]
  );

  return (
    <TopBar.UserMenu
      name={user.name}
      detail={user.email}
      avatar={user.avatar}
      initials={initials}
      customWidth="15rem"
      actions={[
        {
          items: [
            {
              id: "logout",
              content: "Log out",
              onAction: onLogout,
            },
          ],
        },
      ]}
      open={isOpen}
      onToggle={toggle}
    />
  );
}

export default UserMenu;
