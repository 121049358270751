export type Product = {
  id: string;
  title: string;
  status: string;
  updatedAt: string;
  media?: {
    url: string;
    altText?: string;
  };
};

type ProductsResponse = {
  count: number;
  products: {
    id: string;
    title: string;
    status: string;
    updatedAt: string;
    featuredMedia?: {
      preview?: {
        image?: {
          altText?: string;
          url: string;
        };
      };
    };
  }[];
};

export const fetchProducts = async (): Promise<{
  products: Product[];
  count: number;
}> => {
  let products: Product[] = [];
  let count = 0;

  try {
    const response = await fetch("/api/products");

    const { data, error }: { data: ProductsResponse; error: any } =
      await response.json();

    if (error) throw error;

    count = data.count;

    products = data.products.map((item) => ({
      id: item.id,
      title: item.title,
      status: item.status,
      updatedAt: item.updatedAt,
      media: item.featuredMedia?.preview?.image && {
        url: item.featuredMedia.preview.image.url,
        altText: item.featuredMedia.preview.image.altText,
      },
    }));
  } catch (ex) {
    throw ex;
  }

  return { products, count };
};
