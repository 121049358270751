import { EmptyState, LegacyCard, Page } from "@shopify/polaris";

function Home() {
  return (
    <Page title="Home">
      <LegacyCard sectioned>
        <EmptyState
          heading="Welcome To Monify!"
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
          <p>This page will be user's dashboard showing their stats.</p>
        </EmptyState>
      </LegacyCard>
    </Page>
  );
}

export default Home;
