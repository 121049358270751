import { FormLayout, TextField } from "@shopify/polaris";

export type BankAccountDetailsCanada = {
  ownerName: string;
  transitNumber: string;
  institutionNumber: string;
  accountNumber: string;
};

type Props = {
  details: BankAccountDetailsCanada;
  onChange: (value: BankAccountDetailsCanada) => void;
};

function BankAccountFormCanada({ details, onChange }: Props) {
  return (
    <FormLayout>
      <FormLayout.Group>
        <TextField
          label="Account owner name"
          autoComplete="name"
          value={details.ownerName}
          onChange={(value) => onChange({ ...details, ownerName: value })}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField
          label="Transit number"
          autoComplete="bank-transit-number"
          value={details.transitNumber}
          onChange={(value) => onChange({ ...details, transitNumber: value })}
        />
        <TextField
          label="Institution number"
          autoComplete="bank-institution-number"
          value={details.institutionNumber}
          onChange={(value) =>
            onChange({ ...details, institutionNumber: value })
          }
        />
        <TextField
          label="Account number"
          autoComplete="bank-account-number"
          value={details.accountNumber}
          onChange={(value) => onChange({ ...details, accountNumber: value })}
        />
      </FormLayout.Group>
    </FormLayout>
  );
}

export default BankAccountFormCanada;
