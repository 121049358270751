import { BankAccountDetailsCanada } from "./BankAccountFormCanada";
import { BankAccountDetailsUS } from "./BankAccountFormUS";

export enum CountriesEnum {
  USA = "USA",
  CANADA = "Canada",
}

export enum CurrenciesEnum {
  USD = "USD",
  CAD = "CAD",
}

export type State = {
  country: CountriesEnum;
  currency: CurrenciesEnum;
  accountDetails: BankAccountDetailsUS & BankAccountDetailsCanada;
};

export type Action =
  | {
      type: "country";
      payload: CountriesEnum;
    }
  | {
      type: "currency";
      payload: CurrenciesEnum;
    }
  | {
      type: "details";
      payload: BankAccountDetailsCanada | BankAccountDetailsUS;
    };

const defaultAccountDetails = {
  ownerName: "",
  routingNumber: "",
  accountNumber: "",
  transitNumber: "",
  institutionNumber: "",
};

export const initialState: State = {
  country: CountriesEnum.USA,
  currency: CurrenciesEnum.USD,
  accountDetails: defaultAccountDetails,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "country":
      const country = action.payload;

      return {
        ...state,
        country,
        currency: CurrenciesEnum.USD,
        accountDetails: defaultAccountDetails,
      };
    case "currency": {
      const currency = action.payload;

      return {
        ...state,
        currency,
      };
    }
    case "details": {
      const accountDetails = action.payload;

      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          ...accountDetails,
        },
      };
    }
  }
  return state;
}

export default reducer;
