import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

type User = {
  email: string;
  name: string;
  avatar: string;
};

type UserState = {
  isLoading: boolean;
} & (
  | ({
      isLoggedIn: true;
    } & User)
  | {
      isLoggedIn?: false;
    }
);

type AuthContextType = {
  user: UserState;
  onLogout: () => void;
  onOauthCallback: (response: google.accounts.id.CredentialResponse) => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

function AuthProvider({ children }: PropsWithChildren) {
  const isInitializedRef = useRef(false);
  const [user, setUser] = useState<UserState>({
    isLoading: true,
  });

  const onLoggedIn = useCallback((data: User) => {
    setUser({ isLoading: false, isLoggedIn: true, ...data });
  }, []);
  const onLoggedOut = useCallback(() => {
    setUser({ isLoading: false, isLoggedIn: false });
  }, []);

  useEffect(() => {
    if (isInitializedRef.current) return;

    isInitializedRef.current = true;

    fetchUserInfo()
      .then((data) => onLoggedIn(data))
      .catch(() => onLoggedOut());
  }, [onLoggedIn, onLoggedOut]);

  const onOauthCallback = useCallback(
    ({ credential }: google.accounts.id.CredentialResponse) => {
      login(credential)
        .then((data) => {
          onLoggedIn(data);
        })
        .catch(() => {
          onLoggedOut();
        });
    },
    [onLoggedIn, onLoggedOut]
  );

  const onLogout = useCallback(() => {
    onLoggedOut();
  }, [onLoggedOut]);

  const value = useMemo(
    () => ({
      user,
      onLogout,
      onOauthCallback,
    }),
    [onLogout, onOauthCallback, user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const login = async (oauthCode: string): Promise<User> => {
  const response = await fetch(`/api/auth?credential=${oauthCode}`, {
    method: "POST",
  });

  if (!response.ok) throw response;

  return response.json();
};

const fetchUserInfo = async () => {
  const response = await fetch("/api/auth");

  if (!response.ok) throw response;

  return response.json();
};

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);

  if (!context) throw new Error("Could not find `AuthProvider`");

  return context;
}

export function useLoggedInUser() {
  const { user } = useAuth();

  if (!user.isLoggedIn) throw new Error("User is not logged-in!");

  return user;
}

export default AuthProvider;
