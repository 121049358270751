import { useEffect, useRef } from "react";
import { useAuth } from "../../providers/AuthProvider";

function Login() {
  const { onOauthCallback } = useAuth();
  const isInitializedRef = useRef(false);

  useEffect(() => {
    if (isInitializedRef.current) return;

    isInitializedRef.current = true;

    google.accounts.id.initialize({
      client_id:
        "854362735364-65k7svl5ovorr9rssmb03a67s0aoa0ra.apps.googleusercontent.com",
      callback: onOauthCallback,
    });
    google.accounts.id.renderButton(
      window.document.getElementById("google-login-button")!,
      {
        type: "standard",
        theme: "outline",
        size: "large",
        shape: "pill",
      }
    );
  });

  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div id="google-login-button" />
    </div>
  );
}

export default Login;
