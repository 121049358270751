import {
  HomeIcon,
  OrderIcon,
  ProductIcon,
  PersonIcon,
} from "@shopify/polaris-icons";
import { Navigation } from "@shopify/polaris";
import { useLocation } from "react-router";

function SidebarNavigation() {
  const location = useLocation();

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section
        items={[
          {
            url: "/",
            exactMatch: true,
            label: "Home",
            icon: HomeIcon,
          },
          {
            url: "/orders",
            label: "Orders",
            icon: OrderIcon,
          },
          {
            url: "/products",
            label: "Products",
            icon: ProductIcon,
          },
        ]}
      />
      <Navigation.Section fill items={[]} />
      <Navigation.Section
        items={[
          {
            url: "/account",
            label: "Account",
            icon: PersonIcon,
            showVerticalLine: true,
          },
        ]}
        separator
      />
    </Navigation>
  );
}

export default SidebarNavigation;
