import "@shopify/polaris/build/esm/styles.css";
import {
  LegacyCard,
  DataTable,
  Page,
  Spinner,
  InlineStack,
  Thumbnail,
  EmptyState,
} from "@shopify/polaris";
import { NoteIcon } from "@shopify/polaris-icons";
import { useEffect, useRef, useState } from "react";
import { fetchProducts } from "./api";

function Products() {
  const isInitializedRef = useRef(false);
  const [products, setProducts] = useState<{
    rows: any[][];
    count: number;
    isLoading: boolean;
  }>({
    rows: [],
    count: 0,
    isLoading: true,
  });

  useEffect(() => {
    if (isInitializedRef.current) return;

    isInitializedRef.current = true;

    fetchProducts()
      .then((data) => {
        const rows = data.products.map(
          ({ media, title, status, updatedAt }) => [
            <Thumbnail
              size="small"
              source={media?.url || NoteIcon}
              alt={media?.altText || title}
            />,
            title,
            status,
            new Intl.DateTimeFormat("en-US", {
              dateStyle: "medium",
              timeStyle: "short",
            }).format(new Date(updatedAt)),
          ]
        );
        setProducts({
          rows,
          count: data.count,
          isLoading: false,
        });
      })
      .catch(() => {
        setProducts({
          rows: [],
          count: 0,
          isLoading: false,
        });
      });
  }, []);

  const dataTableMarkup = (
    <LegacyCard>
      <DataTable
        verticalAlign="middle"
        firstColumnMinWidth="30px"
        columnContentTypes={["numeric", "text", "text", "text", "text"]}
        headings={["", "Product", "Status", "Last Update"]}
        rows={products.rows}
        footerContent={`Showing ${products.rows.length} of ${products.count} products`}
        totalsName={{
          singular: "Total # of products",
          plural: "Total # of products",
        }}
      />
    </LegacyCard>
  );

  return (
    <Page title="Products">
      {products.isLoading ? (
        <InlineStack align="center">
          <Spinner />
        </InlineStack>
      ) : products.rows.length ? (
        dataTableMarkup
      ) : (
        <LegacyCard sectioned>
          <EmptyState
            heading="You don't have any products yet!"
            image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
          >
            <p>We’ll notify you as soon as they’re available.</p>
          </EmptyState>
        </LegacyCard>
      )}
    </Page>
  );
}

export default Products;
