import "@shopify/polaris/build/esm/styles.css";
import { AppProvider } from "@shopify/polaris";
import { BrowserRouter } from "react-router";
import AuthProvider from "./providers/AuthProvider";
import Shell from "./Shell/Shell";

function App() {
  return (
    <BrowserRouter>
      <AppProvider
        i18n={{
          Polaris: {
            ResourceList: {
              sortingLabel: "Sort by",
              defaultItemSingular: "item",
              defaultItemPlural: "items",
              showing: "Showing {itemsCount} {resource}",
              Item: {
                viewItem: "View details for {itemName}",
              },
            },
            Common: {
              checkbox: "checkbox",
            },
          },
        }}
      >
        <AuthProvider>
          <Shell />
        </AuthProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
