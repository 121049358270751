export type Order = {
  id: string;
  customerName: string;
  updatedAt: string;
  customerLocation: string;
  quantity: number;
  payment: string;
  status: string;
};

type OrdersResponse = {
  count: number;
  precision: "EXACT" | "AT_LEAST";
  orders: {
    id: string;
    name: string;
    updatedAt: string;
    displayFulfillmentStatus: string;
    currentSubtotalLineItemsQuantity: number;
    customer?: {
      displayName: string;
      addresses: [
        {
          city: string;
          country: string;
        }
      ];
    };
    netPaymentSet: {
      presentmentMoney: {
        amount: string;
        currencyCode: string;
      };
    };
  }[];
};

export const fetchOrders = async (): Promise<{
  orders: Order[];
  count: number;
  hasExactCount: boolean;
  totals: string[];
}> => {
  let orders: Order[] = [];
  let totals: string[] = [];
  let count = 0;
  let hasExactCount = true;

  try {
    const response = await fetch("/api/orders");

    const { data, error }: { data: OrdersResponse; error: any } =
      await response.json();

    if (error) throw error;

    orders = data.orders.map((item) => ({
      id: item.name,
      updatedAt: item.updatedAt,
      status: item.displayFulfillmentStatus,
      customerName: item.customer?.displayName || "-",
      customerLocation: item.customer
        ? `${item.customer?.addresses[0].city}, ${item.customer?.addresses[0].country}`
        : "-",
      quantity: item.currentSubtotalLineItemsQuantity,
      payment: formatMoney(
        item.netPaymentSet.presentmentMoney.amount,
        item.netPaymentSet.presentmentMoney.currencyCode
      ),
    }));

    count = data.count;
    hasExactCount = data.precision === "EXACT";

    const currencies: string[] = [
      ...new Set(
        data.orders.map(
          (item) => item.netPaymentSet.presentmentMoney.currencyCode
        )
      ),
    ];

    totals = currencies.map((currency) => {
      const currencyTotal = data.orders
        .filter(
          (item) =>
            item.netPaymentSet.presentmentMoney.currencyCode === currency
        )
        .reduce(
          (acc: number, item) =>
            acc + Number(item.netPaymentSet.presentmentMoney.amount),
          0
        );
      return formatMoney(currencyTotal, currency);
    });
  } catch (ex) {
    throw ex;
  }

  return { orders, totals, count, hasExactCount };
};

function formatMoney(amount: number | string, currency: string) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    currencyDisplay: "code",
  }).format(Number(amount));
}
