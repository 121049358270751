import {
  BlockStack,
  Button,
  Form,
  FormLayout,
  LegacyCard,
  Page,
  Select,
  Text,
} from "@shopify/polaris";
import { useCallback, useReducer } from "react";
import BankAccountFormUS from "./BankAccountFormUS";
import BankAccountFormCanada from "./BankAccountFormCanada";
import reducer, {
  CountriesEnum,
  CurrenciesEnum,
  initialState,
} from "./reducer";

function Account() {
  const [{ country, currency, accountDetails }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const handleSubmit = useCallback(() => {
    console.log({ country, currency, accountDetails });
  }, [accountDetails, country, currency]);

  const BankAccountForm =
    country === CountriesEnum.USA ? BankAccountFormUS : BankAccountFormCanada;

  return (
    <Page title="Bank account details">
      <Form onSubmit={handleSubmit}>
        <BlockStack gap="400">
          <LegacyCard actions={[]} title="Bank account" sectioned>
            <BlockStack gap="400">
              <Text as="p" tone="subdued">
                Funds will be sent to this bank account
              </Text>
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    label="Bank account country"
                    helpText="Your bank needs to be able to accept this currency"
                    value={country}
                    options={[
                      { label: "United States", value: CountriesEnum.USA },
                      { label: "Canada", value: CountriesEnum.CANADA },
                    ]}
                    onChange={(value: CountriesEnum) =>
                      dispatch({
                        type: "country",
                        payload: value,
                      })
                    }
                  />
                  <Select
                    label="Payout currency"
                    disabled={country === CountriesEnum.USA}
                    value={currency}
                    options={[
                      { label: "US Dollar (USD $)", value: CurrenciesEnum.USD },
                      {
                        label: "Canadian Dollar (CAD $)",
                        value: CurrenciesEnum.CAD,
                      },
                    ]}
                    onChange={(value: CurrenciesEnum) =>
                      dispatch({
                        type: "currency",
                        payload: value,
                      })
                    }
                  />
                </FormLayout.Group>
                <BankAccountForm
                  details={accountDetails}
                  onChange={(value) =>
                    dispatch({
                      type: "details",
                      payload: value,
                    })
                  }
                />
              </FormLayout>
            </BlockStack>
          </LegacyCard>
          <Text as="p" tone="subdued">
            By selecting Save, I certify that the information I have provided is
            complete and correct.
          </Text>
          <BlockStack inlineAlign="end">
            <Button submit variant="primary" size="large">
              Save
            </Button>
          </BlockStack>
        </BlockStack>
      </Form>
    </Page>
  );
}

export default Account;
