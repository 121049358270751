import { TopBar as PolarisTopBar } from "@shopify/polaris";
import UserMenu from "./UserMenu";

function TopBar({ onNavigationToggle }: { onNavigationToggle: () => void }) {
  return (
    <PolarisTopBar
      showNavigationToggle
      userMenu={<UserMenu />}
      onNavigationToggle={onNavigationToggle}
    />
  );
}

export default TopBar;
