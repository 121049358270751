import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router";
import Orders from "../Pages/Orders/Orders";
import Home from "../Pages/Home/Home";
import Account from "../Pages/Account/Account";
import Products from "../Pages/Products/Products";

function ProtectedRoutes() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="orders" element={<Orders />} />
      <Route path="products" element={<Products />} />
      <Route path="account" element={<Account />} />
      <Route path="*" element={<RedirectToOrders />} />
    </Routes>
  );
}

function RedirectToOrders() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  });

  return null;
}

export default ProtectedRoutes;
